.btn-primary {
  --bs-btn-bg: #243a5c !important;
  --bs-btn-border-color: #243a5c !important;
}

.btn-light {
  --bs-btn-font-weight: 600;
  --bs-btn-font-color: #212529;
  border-radius: 10px;
  --bs-btn-bg: #f3f1e7 !important;
  --bs-btn-border-color: transparent !important;
}

.tab-pane, .tab-content {
  height: 100%;
}

#rootContainer {
  width: 0;
  height: 1px;
  overflow: hidden;
  container: root-animations / inline-size;
}

:root {
  --panel-table-auto-row-width: 1fr;
  --panel-table-gap: 0px;
  --panel-table-label-column-width: 180px;
  --main-container-side-padding: 30px;
  --search-thumb-min-width: 660px;
  --content-background-color: #f4f4f4;
}

@keyframes scaleInOut {
  0% {
    background-color: #fff;
  }

  10% {
    background-color: #f3f3f3;
  }

  90% {
    background-color: #f3f3f3;
  }

  100% {
    background-color: #fff;
  }
}

@container root-animations (width: 1px) {
  div[class*="modified"] {
    animation: 6s ease-in-out scaleInOut;
  }
}

.btn_logout {
  z-index: 10000;
  position: absolute;
  bottom: 0;
  right: 0;
}

#app {
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
}

body {
  background-color: #f9fafb;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.card {
  max-width: 500px;
  width: 100% !important;
}

.modal-dialog {
  top: 10%;
  max-width: 40% !important;
}
/*# sourceMappingURL=index.988cdca1.css.map */
