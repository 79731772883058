.btn-primary {
    --bs-btn-bg: #243a5c !important;
    --bs-btn-border-color: #243a5c !important;
}

.btn-light {
    --bs-btn-bg: #f3f1e7 !important;
    --bs-btn-border-color: transparent !important;
    --bs-btn-font-weight: 600;
    --bs-btn-font-color: #212529;
    border-radius: 10px;
}

.tab-pane,
.tab-content {
    height: 100%;
}

#rootContainer {
    container-type: inline-size;
    container-name: root-animations;
    overflow: hidden;
    width: 0px;
    height: 1px;
}

:root {
    --panel-table-auto-row-width: 1fr;
    --panel-table-gap: 0px;
    --panel-table-label-column-width: 180px;

    --main-container-side-padding: 30px;
    --search-thumb-min-width: 660px;
    --content-background-color: #f4f4f4;
}

@keyframes scaleInOut {
    0% {
        background-color: white;
    }
    10% {
        background-color: rgb(243, 243, 243);
    }

    90% {
        background-color: rgb(243, 243, 243);
    }

    100% {
        background-color: white;
    }
}

@container root-animations (width: 1px) {
    div[class*='modified'] {
        animation: scaleInOut 6s 1;
        animation-timing-function: ease-in-out;
    }
}
.btn_logout {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10000;
}
#app {
    height: 100vh !important;
    width: 100vw !important;
    position: absolute;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: #f9fafb;
    overflow-x: hidden;
    overflow-y: hidden;
}

.card {
    width: 100% !important;
    max-width: 500px;
}

.modal-dialog {
    max-width: 40% !important;
    top: 10%;
}
